import React from 'react';
// import videoHeader from '../../../static/videos/header-video.mp4';
import videoHeader from '../../../static/videos/alm-print.mp4';
import styled, { keyframes } from 'styled-components';
import { down } from 'styled-breakpoints';
import OurBrands from '../OurBrands';

const StyledVideoHeader = styled.div`
  position: absolute;
  top: 0;
  right: 100px;
  width: 504px;
  height: 547px;
  background-color: #3b5998;
  border-radius: 10px 10px 70px;
  box-shadow: 0 60px 135px rgba(0, 0, 0, 0.14), 0 15px 65px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  z-index: 2;

  ${down('md')} {
    right: 0;
    border-radius: 10px 0 0 50px;
    filter: drop-shadow(0 15px 65px rgba(0, 0, 0, 0.14));
    width: 40%;
    height: 500px;
  }
`;

const VideoBackground = styled.div`
  background-color: #a8dadc;
  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
  background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73));
  position: absolute;
  opacity: 0.6;
  width: 100%;
  height: 100%;
`;

const ClaimTitle = styled.h1`
  font-size: 80px;
  font-weight: 500;
  width: 60%;
  line-height: 1;
  position: relative;
  z-index: 10;
  color: white;

  ${down('md')} {
    font-size: 50px;
    line-height: 1.2;
    width: 80%;
  }
`;

const ClaimDescription = styled.h2`
  font-size: 20px;
  width: 40%;
  color: #f1f1f1;

  ${down('md')} {
    font-size: 18px;
    width: 70%;
    color: #f1f1f1;
    z-index: 3;
    position: relative;
    margin-top: 40px;
  }
`;

const HighlightText = styled.span`
  background: white;
  border-radius: 6px;
  color: #333333;
  border-right: 10px solid white;
  border-left: 10px solid white;
  box-shadow: 0 60px 135px rgba(0, 0, 0, 0.14), 0 15px 65px rgba(0, 0, 0, 0.14);

  ${down('md')} {
    box-shadow: none;
  }
`;

const StyledOurBrands = styled.div`
  position: absolute;
  bottom: 150px;
  width: 100%;
  margin: auto;
`;

const Underline = styled.span`
  //border-bottom: 4px solid #e63946;
  //background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
  //border-bottom: 5px solid;
  //border-image-slice: 1;
  //border-image-source: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
  //border-image-source: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
  //border-image-source: linear-gradient(to top, #f77062 0%, #fe5196 100%);
  //border-image-source: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
  display: inline-block;
  position: relative;

  &:before {
    content: '';
    background: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
    width: 100%;
    height: 12px;
    position: absolute;
    transform: scaleX(1);
    display: block;
    left: 0;
    top: auto;
    bottom: 7px;
    z-index: -1;
    border-radius: 4px;

    ${down('md')} {
      bottom: 8px;
      height: 8px;
    }
  }
`;

const moveAnimation = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;

const StyledWaves = styled(({ className }) => (
  <>
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g>
          {/*<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />*/}

          {/*<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(49, 106, 187, 0.7" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(49, 106, 187, 0.5)" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(49, 106, 187, 0.3)" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />*/}

          {/*<use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(19, 145, 189, 0.7" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(19, 145, 189, 0.5)" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(19, 145, 189, 0.3)" />*/}
          {/*<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />*/}

          <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(0,172,193, 0.7" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(0,172,193, 0.5)" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(0,172,193, 0.3)" />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
  </>
))`
  position: absolute;
  bottom: -4em;
  bottom: 0;
  width: 100%;

  svg {
    position: relative;
    width: 100%;
    //height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;

    use {
      animation: ${moveAnimation} 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;

      &:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
      }

      &:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
      }

      &:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
      }

      &:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
      }
    }
  }
`;

const StyledClaim = styled(({ className, ...props }) => (
  <section className={className} {...props} />
))`
  position: relative;
  padding-top: 120px;
  background-image: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);

  ${down('md')} {
    padding-top: 160px;
    padding-bottom: 130px;
  }

  @media (max-height: 1920px) and (min-width: 767px) {
    height: 970px;
  }
`;

const Claim = () => {
  return (
    <StyledClaim className={'height-80'}>
      <div className="container pos-relative">
        <ClaimTitle>
          <Underline>Diseño</Underline> e Impresión <HighlightText>digital</HighlightText> adaptada
          a tí.
        </ClaimTitle>

        <ClaimDescription>
          {/*Ofrecemos soluciones personalizadas con la mejor calidad de impresión y excelentes acabados.*/}
          Contamos con una gran experiencia en diseño de productos para dar una solución completa a
          tu negocio.
        </ClaimDescription>

        <StyledVideoHeader>
          <VideoBackground />
          <video src={videoHeader} autoPlay={true} loop muted />
        </StyledVideoHeader>
      </div>

      <StyledOurBrands className="hidden-xs">
        <OurBrands />
      </StyledOurBrands>

      <StyledWaves />
    </StyledClaim>
  );
};

export default Claim;
